import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"
import Div100vh from "react-div-100vh"
import Layout from "../components/layout"
import SEO from "../components/seo"
import * as styles from "../css/contact.module.css"

const ContactPage = () => {
  const data = useStaticQuery(graphql`
    {
      email: file(relativePath: { eq: "email.png" }) {
        publicURL
      }
      tmp: file(relativePath: { eq: "tmp.jpg" }) {
        childImageSharp {
          gatsbyImageData(placeholder: NONE, layout: FULL_WIDTH)
        }
      }
      text: file(relativePath: { eq: "tmp-text.png" }) {
        publicURL
      }
    }
  `)

  return (
    <Layout>
      <SEO title="CONTACT" />
      <Div100vh>
        <div className={styles.container}>
          <div className={styles.wrap}>
            <a href="mailto:cianmoore06@gmail.com" className={styles.emailLink}>
              <StaticImage
                src="../img/email.png"
                placeholder="tracedSVG"
                tracedSVGOptions={{
                  color: "#000000",
                }}
              />
            </a>{" "}
            <StaticImage
              className={styles.img}
              src="../img/tmp.jpg"
              draggable={false}
              objectFit="contain"
              placeholder="blurred"
              quality={100}
            />
            <StaticImage
              className={styles.text}
              src="../img/tmp-text.png"
              placeholder="tracedSVG"
              tracedSVGOptions={{
                color: "#000000",
              }}
              height={17}
              objectFit="contain"
            />
          </div>
        </div>
      </Div100vh>
    </Layout>
  )
}

export default ContactPage
